var stored_selects = new Array()
var stored_values = new Array()
var firstClick = false
$(() => {
  var hide_conflicts = function (element) {
    element.find('.assignments select').attr('disabled', 'disabled')
    element.find('select option').addClass('conflict')
    element.find('select option:selected').removeClass('conflict')
    element.find('select option[data-requested="yes"]').removeClass('conflict')
    element.find('select option[data-conflict="no"]').removeClass('conflict')
    element.find('select option[value=""]').removeClass('conflict')
    element.find('select option.conflict').remove()
    element.find('.assignments select').removeAttr('disabled')
  }

  var show_conflicts = function () {
    recall_selects()
  }

  var open_all_assignments = function () {
    var ajaxManager1 = $.manageAjax({ manageType: 'queue', maxReq: 5 })
    $('a.assign-link:visible').each(function (index) {
      var element = $(this)
      element.hide()
      ajaxManager1.add({
        url: element.attr('href'),
        dataType: 'script',
        success: function () {
          store_selects(element.parents('div.game'))
          if ($.cookie('assign-conflicts') == 'hide') {
            hide_conflicts(element.parents('div.game'))
          }
        }
      })
    })
    return false
  }

  var save_all_assignments = function () {
    // $('input.save-assignments').click();
    var ajaxManager1 = $.manageAjax({ manageType: 'queue', maxReq: 5 })
    $("form.edit_game input[type='submit']").each(function (index) {
      var form = $(this).parents('form')
      $(this).parent().hide()
      ajaxManager1.add({
        url: form.attr('action'),
        data: form.serialize(),
        success: function () {
          $(this).parents('div.game').find('a.assign-link').show()
        },
        dataType: 'script',
        type: 'POST'
      })
    })
  }

  var store_selects = function (element) {
    var s = new Array()
    var i = 0
    selects = element.find('.assignments select')
    for (i = 0; i < selects.length; i++) {
      stored_selects[selects[i].id] = selects[i].innerHTML
      stored_values[selects[i].id] = $('#' + selects[i].id).val()
    }
  }

  var recall_selects = function () {
    selects = $('.assignments select')
    var i
    for (i = 0; i < selects.length; i++) {
      v = selects[i].value
      h = stored_selects[selects[i].id]
      $('#' + selects[i].id)
        .html(h)
        .val(v)
    }
  }

  var is_dirty = function () {
    var isDirty = false
    selects = $('.assignments select')
    for (i = 0; i < selects.length; i++) {
      if (selects[i].value != stored_values[selects[i].id]) {
        $('#' + selects[i].id)
          .closest('div.game')
          .addClass('dirty').addClass
        isDirty = true
      }
    }
    return isDirty
  }
  // thanks http://stackoverflow.com/questions/140460
  // you have unsaved changes...
  var confirmExitIfModified = (function () {
    return function (message) {
      window.onbeforeunload = function (e) {
        e = e || window.event
        if (is_dirty()) {
          // For IE and Firefox
          if (e) {
            e.returnValue = message
          }
          // For Safari
          return message
        }
      }
    }
  })()

  confirmExitIfModified('You have unsaved assignment changes.')

  var form = $('form.edit_game, form.new_game')
  var zone = form.find('#game_zone_id')
  // var venue = form.find('#game_venue_id')
  // form
  //   .find('select.selectable')
  //   .removeClass('form-control')
  //   .select2({ allowClear: true })

  // // clear subvenue on changed venue
  // $('#game_venue_id').on('change', function () {
  //   $('#game_subvenue').val('')
  // })

  // set the default zone on the association modals to be the currently shown zone
  $('.association-zone').val($('#game_zone_id').val())

  // on change of zone, clear venue, age, home/away team
  $('#game_zone_id').on('change', function () {
    // have to add a blank option here because it likely does not exist
    var blankOption = new Option('Please Choose...', '', false, false)
    var selects = $(
      [
        '#game_venue_id',
        '#game_age_group_id',
        '#game_home_team_id',
        '#game_away_team_id',
        '#game_payor_id'
      ].join(', ')
    )
    // remove the blank option if it exists
    selects.find('option[value=""]').remove()
    // add the blank option and set the value of the select to blank.
    selects.prepend(blankOption).val('').trigger('change')
    $('.association-zone').val($('#game_zone_id').val())
  })

  $('#game_away_team_id, #game_home_team_id').select2({
    allowClear: true,
    ajax: {
      url: '/autocomplete/teams.json',
      cache: true,
      data: function (params) {
        return {
          q: params.term, // search term
          page: params.page,
          zone_id: $('#game_zone_id').val()
        }
      }
    }
  })

  $('#game_age_group_id').select2({
    allowClear: true,
    ajax: {
      url: '/autocomplete/age_groups.json',
      cache: true,
      data: function (params) {
        return {
          q: params.term, // search term
          page: params.page,
          zone_id: $('#game_zone_id').val()
        }
      }
    }
  })

  $('#game_league_id').select2({
    allowClear: true,
    ajax: {
      url: '/autocomplete/leagues.json',
      cache: true,
      data: function (params) {
        return {
          q: params.term, // search term
          page: params.page,
          zone_id: $('#game_zone_id').val()
        }
      }
    }
  })

  $('#game_venue_id.selectable').select2({
    allowClear: true,
    ajax: {
      url: '/autocomplete/venues.json',
      cache: true,
      data: function (params) {
        return {
          q: params.term, // search term
          page: params.page,
          zone_id: $('#game_zone_id').val()
        }
      }
    }
  })

  $('#game_assignor_id').select2({
    allowClear: true,
    ajax: {
      url: '/autocomplete/assignors.json',
      cache: true,
      data: function (params) {
        return {
          q: params.term, // search term
          page: params.page,
          zone_id: $('#game_zone_id').val(),
          existing_assignor_id: $('#game_assignor_id').val(),
        }
      }
    }
  })
  if ($('#game_zone_id').length > 0){
    $('#game_payor_id').select2({
      ajax: {
        url: '/autocomplete/payors.json',
        cache: true,
        data: function (params) {
          return {
            q: params.term, // search term
            page: params.page,
            zone_id: $('#game_zone_id').val()
          }
        }
      }
    })  
  }

  $('#games .game-table-delete-button').on('click', function (e) {
    e.preventDefault()
    var url = $(this).attr('href')
    var description = $(this).parents('div.game').find('h4:last').text()
    var subtitle = $(this).parents('div.game').find('h5:first').text()
    var modal = $('#delete-game-confirmation-modal')
    modal.find('#delete-game-link').attr('href', $(this).attr('href'))
    modal.find('#delete-game-description').text(description)
    modal.find('#delete-game-subtitle').text(subtitle)
    modal.modal('show')
  })

  // game request
  // maybe tie to ajax:before ?
  $('body.games').on('submit', 'form.new_game_request', function () {
    $(this)
      .find("input[type='submit']")
      .attr('value', 'Requesting...')
      .attr('disabled', 'disabled')
    $(this)
      .parents('.game-table-assignments')
      .find("form.new_game_request input[type='submit']:enabled")
      .hide()
  })

  // accept/decline
  $('#games').on('submit', 'form.new_confirmation.accept', function () {
    $(this).find("input[type='submit']").attr('disabled', 'disabled')
  })

  // toggle checkbox for published
  $('#games')
    .on('ajax:success', 'a.game-publish', function () {
      $(this).hide()
      $(this).siblings('.label.published-game').show()
    })
    .on('ajax:error', 'a.game-publish', function () {
      alert(
        'This game could not be published. Please refresh the page and try again.'
      )
    })

  // hide the appropriate link on startup
  if ($.cookie('assign-conflicts') == 'hide') {
    $('.conflicts-hide').hide()
  } else {
    $('.conflicts-show').hide()
  }

  //click behaviors

  $('.conflicts-hide a').on('click', function (event) {
    hide_conflicts($('#games'))
    $.cookie('assign-conflicts', 'hide')
    $('.conflicts-hide').hide()
    $('.conflicts-show').show()
    return false
  })
  $('.conflicts-show a').on('click', function (event) {
    show_conflicts()
    $.cookie('assign-conflicts', 'show')
    $('.conflicts-show').hide()
    $('.conflicts-hide').show()
    return false
  })
  $('a#assign-edit-all').on('click', function (event) {
    event.preventDefault()
    open_all_assignments()
  })
  $('a#assign-save-all').on('click', function (event) {
    event.preventDefault()
    save_all_assignments()
  })

  // $('#games').on('change', '.assignments select', function () {
  //   $(this).trigger('assign:change')
  //   if ($(this).val() == '') {
  //     return false
  //   }
  //   var game_id = $(this).parents('div.game').attr('data-game-id')
  //   var position_id = $(this).data('position-id')
  //   var userUrl = `/users/${$(
  //     this
  //   ).val()}.js?game_id=${game_id}&position_id=${position_id}`
  //   $.get(
  //     userUrl,
  //     function (data) {
  //       $('#game_' + game_id + ' .referee-detail')
  //         .html(data)
  //         .show()
  //       if (!$.cookie('assign-tab')) {
  //         $.cookie('assign-tab', 'today')
  //       }
  //       selector =
  //         '#game_' + game_id + ' a[data-type="' + $.cookie('assign-tab') + '"]'
  //       $(selector).click()
  //     },
  //     'html'
  //   )
  // })

  // toggle  the tabs
  // $('#games').on('click', 'ul.nav-tabs li a', function (event) {
  //   container = $(this)
  //     .closest('div.box-footer')
  //     .siblings('div.box-content:first')
  //   previousHeight = container.attr('data-height')
  //     ? container.attr('data-height')
  //     : 0
  //   container.attr('data-height', Math.max(previousHeight, container.height()))
  //   container.css('min-height', container.attr('data-height') + 'px')
  //   $.cookie('assign-tab', $(this).attr('data-type'))
  // })

  $('a.assign-link')
    .on('click', function () {
      element = $(this)
      var row = $(this).parents('tr')
      $(this).hide()
    })
    .bind('ajax:complete', function () {
      store_selects($(this).parents('div.game'))
      if ($.cookie('assign-conflicts') == 'hide') {
        hide_conflicts($(this).parents('div.game'))
      }
    })

  $('body').on('click', '.save-assignments', function () {
    $(this).parent().hide()
    $(this).bind('ajax:complete', function () {
      $(this).parents('div.game').find('a.assign-link').show()
    })
  })
})
